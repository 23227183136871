import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { PostalCodesMappingsEntity } from '@core/entities/postal-codes/postal-codes-mappings.entity';
import { PostalCodesResponseEntity } from '@core/entities/postal-codes/postal-codes-response.entity';
import { PostalCodesStatisticsEntity } from '@core/entities/postal-codes/postal-codes-statistics.entity';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deserialize, serialize } from 'serializr';
import {
  GetPostalCodeLookupResponse,
} from '@core/models/responses/id-api/get-postal-code-lookup/get-postal-code-lookup-response';
import idApiConfig from '@config/apis/id/id-api.config';
import { FfNgxUrlHelper } from '@fagforbundet/ngx-components';

@Injectable({
  providedIn: 'root',
})
export class PostalCodeService {
  #httpClient = inject(HttpClient);

  getPostalCodesStatistics(): Observable<PostalCodesStatisticsEntity> {
    return this.#httpClient.get(EnvironmentHelper.fetchAPIBase('v1/postal-codes/statistics')).pipe(
      map((data: { statistics: object }) => {
        return deserialize(PostalCodesStatisticsEntity, data.statistics);
      }),
    );
  }

  postPostalCodes(file: File): Observable<PostalCodesStatisticsEntity> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.#httpClient.post(EnvironmentHelper.fetchAPIBase('v1/postal-codes'), formData).pipe(
      map((response: { statistics: object }) => {
        return deserialize(PostalCodesStatisticsEntity, response.statistics);
      }),
    );
  }

  updateMunicipalityMappings(mappings: PostalCodesMappingsEntity[]): Observable<PostalCodesResponseEntity> {
    return this.#httpClient
      .post(EnvironmentHelper.fetchAPIBase('v1/postal-codes/municipality-mappings'), {
        mappings: serialize(mappings),
      })
      .pipe(
        map((response: object) => {
          return deserialize(PostalCodesResponseEntity, response);
        }),
      );
  }

  postalCodeLookup(
    postalCode: string,
  ): Observable<GetPostalCodeLookupResponse> {
    return this.#httpClient
      .get<GetPostalCodeLookupResponse>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.GET_POSTAL_CODE_LOOKUP.path,
          { postalCode },
        ).toString(),
      );
  };
}
