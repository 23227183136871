import { PostalCodesMappingsEntity } from '@core/entities/postal-codes/postal-codes-mappings.entity';
import { PostalCodesStatisticsEntity } from '@core/entities/postal-codes/postal-codes-statistics.entity';
import { alias, list, object, serializable } from 'serializr';

export class PostalCodesResponseEntity {
  @serializable(alias('mappings', list(object(PostalCodesMappingsEntity))))
  private _mappings: PostalCodesMappingsEntity[] = [];

  @serializable(alias('mappingsAffectedUsers'))
  private _mappingsAffectedUsers: number;

  @serializable(alias('statistics', object(PostalCodesStatisticsEntity)))
  private _statistics: PostalCodesStatisticsEntity;

  get mappings(): PostalCodesMappingsEntity[] {
    return this._mappings;
  }

  get mappingsAffectedUsers(): number {
    return this._mappingsAffectedUsers;
  }

  get statistics(): PostalCodesStatisticsEntity {
    return this._statistics;
  }
}
