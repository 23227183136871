import { alias, date, serializable } from 'serializr';

export class PostalCodesStatisticsEntity {
  @serializable(alias('latestUpdatedAt', date()))
  private _latestUpdatedAt: Date;

  @serializable(alias('postalCodesCount'))
  private _postalCodesCount: number;

  get latestUpdatedAt(): Date {
    return this._latestUpdatedAt;
  }

  get postalCodesCount(): number {
    return this._postalCodesCount;
  }
}
