import { alias, serializable } from 'serializr';

export class PostalCodesMappingsEntity {
  @serializable(alias('oldMCode'))
  private _oldMCode: string;

  @serializable(alias('newMCode'))
  private _newMCode: string;

  constructor(oldMCode: string, newMCode: string) {
    this._oldMCode = oldMCode;
    this._newMCode = newMCode;
  }

  get oldMCode(): string {
    return this._oldMCode;
  }

  set oldMCode(value: string) {
    this._oldMCode = value;
  }

  get newMCode(): string {
    return this._newMCode;
  }

  set newMCode(value: string) {
    this._newMCode = value;
  }
}
